import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body, Headline, Hr, List } from '@sumup/circuit-ui';

import { ExternalLink, InternalLink } from 'shared/components/Link';
import { getChannelLink } from 'shared/utils/channel-link';

const StyledBody = styled(Body)(
  css`
    font-size: inherit !important;
  `,
);

const StyledBodyItalic = styled(Body)(
  css`
    font-size: inherit !important;
    font-weight: inherit !important;
    font-style: italic;
  `,
);

const StyledList = styled(List)(css`
  li {
    margin-bottom: 0;
  }
`);

const defaultRenderMarkOptions = {
  [MARKS.BOLD]: (text) => (
    <StyledBody variant="highlight" as="strong">
      {text}
    </StyledBody>
  ),
  [MARKS.ITALIC]: (text) => <StyledBodyItalic as="p">{text}</StyledBodyItalic>,
};

export const defaultRenderOptions = {
  renderMark: defaultRenderMarkOptions,
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const href = node.data.uri as string;
      const isExternalLink = href.startsWith('http');

      return (
        <>
          {isExternalLink && (
            <ExternalLink href={href}>{children}</ExternalLink>
          )}
          {!isExternalLink && (
            <InternalLink href={getChannelLink(href)}>{children}</InternalLink>
          )}
        </>
      );
    },
    [BLOCKS.UL_LIST]: (_, children) => (
      <StyledList size="two">{children}</StyledList>
    ),
    [BLOCKS.OL_LIST]: (_, children) => (
      <StyledList variant="ordered" size="one">
        {children}
      </StyledList>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <Headline
        size="two"
        as="h2"
        css={{ marginBottom: 'var(--cui-spacings-byte)' }}
      >
        {children}
      </Headline>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Headline
        size="three"
        as="h3"
        css={{ marginBottom: 'var(--cui-spacings-byte)' }}
      >
        {children}
      </Headline>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <Headline
        size="four"
        as="h4"
        css={{ marginBottom: 'var(--cui-spacings-byte)' }}
      >
        {children}
      </Headline>
    ),
    [BLOCKS.HR]: () => <Hr />,
    [BLOCKS.QUOTE]: (_, children) => (
      <Body variant="quote" as="blockquote">
        {children}
      </Body>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => <span>{children}</span>,
  },
};
