import {
  documentToReactComponents,
  type RenderText,
  type RenderMark,
  type RenderNode,
} from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import type { FC } from 'react';

export interface RichTextProps {
  richText: Document;
  renderNode: RenderNode;
  renderMark: RenderMark;
  renderText?: RenderText;
}

export const RichText: FC<RichTextProps> = ({
  richText,
  renderNode,
  renderMark,
  renderText,
}) => {
  if (!richText) {
    return null;
  }

  return (
    <>
      {documentToReactComponents(richText, {
        renderNode,
        renderMark,
        renderText,
      })}
    </>
  );
};
